import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Img from '../Img';
import Text from '../Text';
import intelLogo from '../assets/intellihealth_white.png';

const IntelliWrapper = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  z-index: 2;

  .copy-right {
    margin-top: 8px;
  }

  .ant-typography {
    color: #fff;
  }

  a {
    color: #fff;
  }

  img {
    height: 26px;
  }

  ${props =>
    props.invert &&
    css`
      img {
        filter: invert(70%);
        -webkit-filter: invert(70%);
      }
      .ant-typography {
        color: #1d1d1f !important;
      }
      a {
        color: #137ac9 !important;
      }
    `}
`;

const Intellihealth = props => (
  <IntelliWrapper invert={props.invert}>
    <div>
      <Text level={2}>Powered By </Text>
      <Img srcSet={`${intelLogo} 2x`} />
    </div>
    <div className='copy-right'>
      <Text>
        Copyright &copy; {new Date().getFullYear()} |{' '}
        <Link to='/terms-and-conditions' target='_blank'>
          Terms and Conditions
        </Link>{' '}
        |{' '}
        <Link to='/privacy-policy' target='_blank'>
          Privacy Policy
        </Link>
      </Text>
    </div>
  </IntelliWrapper>
);

/** @component */
export default Intellihealth;
