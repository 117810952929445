import React from 'react';
import styled from 'styled-components';
import { Progress as ProgressComponent, Typography } from 'antd';

const { Paragraph } = Typography;
const ProgressWrapper = styled.div`
  .ant-progress-status-normal {
    .ant-progress-bg {
      background: #5189c3;
    }
  }

  .ant-progress-status-active {
    .ant-progress-bg {
      background: #5189c3;
    }
  }

  .ant-progress-status-exception {
    .ant-progress-bg {
      background: #d92d20;
    }

    .anticon-close-circle {
      color: #d92d20;
    }
  }

  .ant-progress-status-success {
    .ant-progress-bg {
      background: #039855;
    }

    .anticon-check-circle {
      color: #039855;
    }
  }
`;

const Progress = props => (
  <ProgressWrapper>
    <ProgressComponent
      type={props.type}
      status={props.status}
      percent={props.percent}
      strokeColor={props.color}
      trailColor={props.trailColor}
      width={props.width}
      strokeWidth={props.strokeWidth}
      format={props.format}
      showInfo={props.showInfo}
    />
    <Paragraph>{props.text}</Paragraph>
  </ProgressWrapper>
);

Progress.defaultProps = {
  text: '',
  status: '',
  showInfo: true,
  color: '',
};

export default Progress;
