import React from 'react';
import styled from 'styled-components';
import Layout from 'style-guide/Layout';
import Brand from 'style-guide/Brand';
import Text from 'style-guide/Text';

const { Footer: footer } = Layout;

const MainFooter = styled(footer)`
  padding: 24px 0;
  text-align: center;
  background: transparent;

  a {
    color: #626469;
  }

  img {
    width: 100px;
    height: 25px;
    position: relative;
    top: -2px;
  }

  @media screen and (max-width: 767px) {
    text-align: center;

    .brand-name {
      display: block;
    }
  }
`;

const Footer = ({ clientUrl = '/' }) => (
  <MainFooter>
    <Text level={1} className='brand-name'>
      Powered By <Brand.Flyte type={1} level={2} size='1x' />
    </Text>
    <Text level={1}>
      © {new Date().getFullYear()} |{' '}
      <a href='/terms-and-conditions' target='_blank'>
        Terms and Conditions
      </a>{' '}
      |{' '}
      <a href='/privacy-policy' target='_blank'>
        Privacy Policy
      </a>
    </Text>
  </MainFooter>
);

export default Footer;
