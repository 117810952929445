import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as typography } from 'antd';

const { Text: Component } = typography;

const text = ({ color, state, secondary, ...props }) => <Component {...props} />;

export const fontSizes = {
  1: 12,
  2: 14,
  3: 16,
  4: 18,
  5: 20,
  6: 22,
};

export const Text = styled(text)`
  font-family: 'Centra No2';
  font-size: ${props => fontSizes[props.level]}px;
  color: #1d1d1f;

  svg:first-of-type {
    margin-right: 8px;
    position: relative;
    top: 4px;
  }

  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft}px;
    `}

  ${props =>
    props.secondary &&
    css`
      color: #626469;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
  ${props =>
    props.type === 'success' &&
    css`
      color: #039855 !important;
    `}
`;

Text.defaultProps = {
  level: 2,
};

// don't forward ref to the Text component so that it works smoother
// with Popover and Tooltip components
/** @component */
export default React.forwardRef((props, ref) => <Text {...props} />);
