import styled from 'styled-components';
import { Input as input } from 'antd';
import { forwardRef } from 'react';

const InputWrapper = styled(input)`
  font-size: 16px;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  box-shadow: none;

  &::placeholder,
  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: #626469;
    opacity: 1; /* Firefox */
  }

  &:hover,
  &:focus {
    border-color: #137ac9;
    box-shadow: none;
  }

  .error-input-caption {
    color: #d92d20;
  }
`;

const Input = forwardRef((props, ref) => <InputWrapper {...props} ref={ref} />);

Input.Search = input.Search;
Input.Group = input.Group;
Input.TextArea = input.TextArea;
Input.Password = input.Password;

/** @component */
export default Input;
