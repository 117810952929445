var api = require("!../../../../../node_modules/.pnpm/style-loader@1.3.0_webpack@4.44.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@4.44.0/node_modules/css-loader/dist/cjs.js!../../../../../node_modules/.pnpm/less-loader@7.3.0_less@4.1.3_webpack@4.44.0/node_modules/less-loader/dist/cjs.js??ref--5-oneOf-8-2!./notification.less");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};