import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as typography } from 'antd';

const { Paragraph: Component } = typography;

export const fontSizes = {
  1: 12,
  2: 14,
  3: 16,
  4: 18,
  5: 20,
  6: 22,
};

const paragraph = props => <Component {...props} />;

const Paragraph = styled(paragraph)`
  font-family: 'Centra No2';
  white-space: pre-line;
  ${props =>
    props.patientSummaryItem &&
    css`
      margin-left: ${props.patientSummaryItem};
    `}
  font-size: ${props => fontSizes[props.level]}px !important;
  color: #1d1d1f;
  display: block !important;
  ${props =>
    props.$hasPadding &&
    css`
      padding: 12px;
    `}

  svg {
    position: relative !important;
    top: ${props => (props.bmiq6683FixReviewIcon ? '2' : '-2')}px !important;
    left: 0 !important;
  }
`;
Paragraph.defaultProps = {
  level: 2,
};
/** @component */
export default Paragraph;
