import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import Select from '../Select';

const { Option } = Select;

const selectComponent = React.forwardRef(({ optionsData, ...props }, ref) => {
  const options = React.useMemo(
    () =>
      optionsData.map(item => {
        const { id, name, disabled = false } = item;
        return (
          <Option key={id} value={id} data-testid={name} disabled={disabled}>
            {name}
          </Option>
        );
      }),
    [optionsData],
  );

  return (
    <Select allowClear {...props} ref={ref}>
      {options}
    </Select>
  );
});
const BmiqSelect = styled(selectComponent)`
  && {
    ${props =>
      props.theme &&
      props.theme === 'dark' &&
      css`
        .ant-select-selection {
          background-color: #e8e8e8;
          border: none;
        }

        .ant-select-selection__placeholder {
          font-size: 12px;
          color: #1d1d1f;
        }

        .ant-select-selection--single {
          height: 30px;
        }

        .ant-select-selection__rendered {
          line-height: 28px;
        }

        .ant-select-selection-selected-value {
          font-size: 12px;
          color: #1d1d1f;
        }

        .ant-select-selection:active {
          border-color: #e8e8e8;
          box-shadow: none;
        }

        .ant-select-selection:hover {
          border-color: #e8e8e8;
          box-shadow: none;
        }

        .ant-select-selection:focus {
          border-color: #e8e8e8;
          box-shadow: none;
        }

        .ant-select-selection__clear {
          background: #e8e8e8;
        }
      `}
  }
`;

BmiqSelect.propTypes = {
  /** an array of objects containing id and name where id will be passed as value to Option
   @param {string} id id of the option<br/>
   @param {string} name name of the option
   */
  optionsData: propTypes.array,
};

BmiqSelect.defaultProps = {
  optionsData: [],
};

/** @component */
export default BmiqSelect;
